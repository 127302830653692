.NotAuthenticatedRoot {
  padding: 3rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  background-color: var(--bg-fundo-secondary);
}

.NotAuthenticatedTitle {
  margin: 1rem;
  font-size: 2rem;
  color: var(--title-dark);
}

.NotAuthenticatedLogo {
  width: 283px;
  color: var(--title-dark);
}
