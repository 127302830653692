.UserNewHeaderRoot {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.UserNewHeaderBox {
  width: 100%;
  padding: 5px;
  background-color: var(--bg-fundo-secondary);
}

.UserNewHeaderImgLogoBox {
  display: inline-block;
  width: 50px;
  align-items: center;
}

.UserNewHeaderImgLogo {
  width: 100%;
  max-width: 3rem;
  padding: 5px;
  border-radius: 16px;
  background: var(--orange);
}

.UserNewHeaderTitleBox {
  width: 70%;
  display: inline-block;
  margin: 0px 0px 0px 6px;
  vertical-align: center;
  align-items: center;
}

.UserNewHeaderButtonBox {
  width: 25%;
  display: inline-block;
  margin: 0px 0px 0px 6px;
  vertical-align: center;
  align-items: center;
}

.UserNewHeaderTitle {
  font-size: 1rem;
  font-weight: bold;
  color: var(--title);
  font-family: var(--font-default);
  box-sizing: border-box;
}

.UserNewHeaderSubTitle {
  font-size: 0.8rem;
  font-weight: bold;
  text-decoration: underline;
  color: var(--titleChildren);
  font-family: var(--font-default);
  box-sizing: border-box;
}

.UserNewHeaderInfoBox {
  padding: 3px;
  background: var(--bg-fundo-tertiary);
}

.UserNewHeaderFieldsBox {
  width: 100%;
  padding: 1px;
}

.UserNewHeaderFieldsLabel {
  font-size: 0.8rem;
  font-weight: bold;
  font-family: var(--font-default);
  color: var(--label);
}

.UserNewHeaderFieldsText {
  font-size: 1rem;
  font-family: var(--font-default);
  color: var(--inputfield);
}

.UserNewFeedBox {
  width: 100%;
  padding: 5px;
}

.UserNewFeedImgLogo {
  width: 100%;
  max-width: 2rem;
  padding: 5px;
  border-radius: 16px;
  background: var(--blue);
}

.UserNewFeedItemImg {
  width: 1rem;
  height: 1rem;
  padding: 5px;
  border-radius: 50%;
  background: var(--gray);
  color: var(--white);
}

@media (max-width: 800px) {
  .UserNewHeaderTitle {
    font-size: 0.8rem;
  }

  .UserNewHeaderSubTitle {
    font-size: 0.7rem;
  }
  .UserNewHeaderFieldsLabel {
    font-size: 0.7rem;
  }
}

@media (max-width: 400px) {
  .UserNewHeaderTitle {
    font-size: 0.8rem;
  }
  .UserNewHeaderSubTitle {
    font-size: 0.7rem;
  }
  .UserNewHeaderFieldsLabel {
    font-size: 0.7rem;
  }
}
