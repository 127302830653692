.AssociatesReportRoot {
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  background-color: var(--bg-fundo-secondary);
}

#formAssociatesReport span {
  font-size: 0.9rem;
  color: var(--text-message);
}

#formAssociatesReport button span {
  font-size: 0.9rem;
  color: #ffffff;
}

.AssociatesReportModalTitle {
  font-size: 1rem;
  font-weight: bold;
  color: var(--titleModal);
}

@media (max-width: 800px) {
  .AssociatesReportModalTitle {
    font-size: 0.9rem;
  }
}

@media (max-width: 400px) {
  .AssociatesReportModalTitle {
    font-size: 0.8rem;
  }
}
