.ProfileRoot {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  width: 100%;
}

.ProfileLabel {
  font-size: 0.9rem;
  text-decoration: none;
  font-weight: bold;
  color: var(--gray-dark);
  padding: 2px;
  margin: 2px;
}

.ProfileTitle {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--title);
}
.ProfileBoxTitle {
  width: 100%;
  border-bottom: 1px solid var(--title);
}

.ProfileRootRow {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}

.ProfileForm {
  margin: 2rem 0rem 0rem 0rem;
  padding: 1.5rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  width: 80%;
  min-height: 300px;
  border-radius: 16px;
  background-color: var(--bg-fundo-primary);
  -webkit-box-shadow: 10px 10px 24px 5px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 10px 10px 24px 5px rgba(0, 0, 0, 0.51);
  box-shadow: 10px 10px 24px 5px rgba(0, 0, 0, 0.51);
}

@media (max-width: 800px) {
  .ProfileLabel {
    font-size: 0.8rem !important;
  }

  .ProfileTitle {
    font-size: 2rem;
  }
}

@media (max-width: 400px) {
  .ProfileLabel {
    font-size: 0.7rem !important;
  }
  .ProfileTitle {
    font-size: 1.5rem;
  }
}
