#HeaderLogo {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 90px;
  font-size: 1rem;
  text-decoration: none;
  font-weight: bold;
  text-shadow: var(--shadow) 1px 3px 1px;
  color: var(--title);
}
#HeaderLogo img {
  width: 70%;
}
@media (max-width: 800px) {
  #HeaderLogo {
    font-size: 1rem;
  }
  #HeaderLogo img {
    width: 85%;
  }
}

.imgLogo {
  width: 100%;
  max-height: 50px;
}

.imgLogoUser {
  position: fixed;
  top: 2px;
  left: 2px;
  max-width: 70px;
  max-height: 70px;
}

@media (max-width: 400px) {
  #HeaderLogo {
    font-size: 0.8rem;
  }
  #HeaderLogo img {
    width: 85%;
  }
}
