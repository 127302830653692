.LoadingBox {
  width: 100%;
  height: fit-content;
  margin: 0px;
  padding: 5px;
  position: fixed;
  top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: transparent;
  z-index: 9999999;
}
