.UsersRoot {
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  background-color: var(--bg-fundo-secondary);
}

#formUsers span {
  font-size: 0.9rem;
  color: var(--text-message);
}

#formUsers button span {
  font-size: 0.9rem;
  color: #ffffff;
}

@media (max-width: 800px) {
}

@media (max-width: 400px) {
}
