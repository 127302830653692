#MenuLeftRoot {
  background-color: var(--bg-fundo-secondary);
}

#MenuLeftRoot a {
  font-size: 0.8rem;
  text-decoration: none;
  color: var(--link);
}

#MenuLeftRoot a:hover {
  font-size: 0.8rem;
  text-decoration: underline;
  font-weight: bold;
  color: var(--linkhover);
}

.MenuItemSelected {
  color: var(--menu-selected);
}

.MenuItem {
  color: var(--breadcrumbs-text);
}

.MenuleftDrawer {
  min-height: 1rem;
}
