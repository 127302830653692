.ChildrenRoot {
  padding: 1.5rem 1rem 1rem 5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  width: 100%;
  min-height: 88vh;
  background-color: var(--bg-fundo-children);
}

.FormInput {
  color: var(--inputfield) !important;
  font-size: 0.9rem !important;
}

.FormInputDisabled {
  color: var(--gray) !important;
  font-size: 0.9rem !important;
}

.FormInput100 {
  width: 100% !important;
}

.FormInput50 {
  width: 50% !important;
}

.FormRoot {
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  width: 100%;
  min-height: 100px;
  background-color: var(--bg-fundo-primary);
}

.FormRoot label {
  font-size: 0.8rem !important;
  font-weight: bold !important;
  color: var(--label) !important;
}

.FormTitle {
  font-size: 1rem;
  font-style: italic;
  color: var(--titleChildren);
}

.Breadcrumbs {
  margin-bottom: 1px;
  width: 100%;
}
.Breadcrumbs a {
  text-decoration: underline;
  font-size: 0.8rem;
  color: var(--breadcrumbs-text) !important;
}

.Mui-expanded {
  margin: 2px auto !important;
}
.MuiButtonBase-root {
  min-height: 30px !important;
  /* background-color: var(--bg-fundo-tertiary) !important; */
  font-size: 0.8rem !important;
}

.ButtonFinish {
  height: 48px;
  margin: 3px;
  padding: 5px;
  min-width: 30%;
  background-color: var(--bg-button-finish) !important;
  color: var(--button-ok-text) !important;
  font-weight: bold !important;
  border-radius: 16px !important;
  border: 0px;
}

.ButtonFinish:hover {
  background-color: var(--bg-button-finish-over) !important;
}

.ButtonOK {
  height: 48px;
  margin: 3px;
  width: 60%;
  background-color: var(--button-ok-bg) !important;
  color: var(--button-ok-text) !important;
  font-weight: bold !important;
  border-radius: 16px !important;
  border: 0px;
}

.ButtonCancel {
  height: 48px;
  margin: 3px;
  width: 60%;
  background-color: var(--button-cancel-bg) !important;
  color: var(--button-cancel-text) !important;
  font-weight: bold !important;
  border-radius: 16px !important;
  border: 0px;
}

.ButtonDark {
  height: 48px;
  margin: 3px;
  padding: 5px;
  width: 60%;
  background-color: var(--bg-fundo-dark) !important;
  color: var(--button-ok-text) !important;
  font-weight: bold !important;
  border-radius: 16px !important;
}

.ButtonBlueDark {
  height: 48px;
  margin: 3px;
  padding: 5px;
  width: 60%;
  background-color: var(--blue-dark) !important;
  color: var(--button-ok-text) !important;
  font-weight: bold !important;
  border-radius: 16px !important;
}

.ButtonRedDark {
  height: 48px;
  margin: 3px;
  padding: 5px;
  width: 60%;
  background-color: var(--red-dark) !important;
  color: var(--button-ok-text) !important;
  font-weight: bold !important;
  border-radius: 16px !important;
}

.ButtonPrimary {
  height: 48px;
  margin: 3px;
  padding: 5px;
  min-width: 60%;
  background-color: var(--bg-button-primary) !important;
  color: var(--button-ok-text) !important;
  font-weight: bold !important;
  border-radius: 16px !important;
  border: 0px;
}

.ButtonPrimary:hover {
  background-color: var(--bg-button-primary-over) !important;
}

.ButtonDefault {
  height: 48px;
  margin: 3px;
  padding: 5px;
  min-width: 60%;
  background-color: var(--bg-button-default) !important;
  color: var(--button-ok-text) !important;
  font-weight: bold !important;
  border-radius: 16px !important;
  border: 0px;
}

.ButtonDefault:hover {
  background-color: var(--bg-button-default-over) !important;
}

.ButtonDanger {
  height: 48px;
  margin: 3px;
  padding: 5px;
  min-width: 60%;
  background-color: var(--bg-button-danger) !important;
  color: var(--button-ok-text) !important;
  font-weight: bold !important;
  border-radius: 16px !important;
  border: 0px;
}

.ButtonDanger:hover {
  background-color: var(--bg-button-danger-over) !important;
}

.ModalRoot {
  padding: 10px;
  min-width: 300px;
  min-height: 100px;
}

.ModalMessage {
  color: var(--text-message);
  font-size: 0.8rem;
  font-weight: bold;
  font-style: italic;
}
@media (max-width: 800px) {
  .FormInput {
    font-size: 0.8rem !important;
  }
  .FormInputDisabled {
    font-size: 0.8rem !important;
  }
  .FormTitle {
    font-size: 0.9rem;
  }
}

@media (max-width: 400px) {
  .FormInput {
    font-size: 0.7rem !important;
  }
  .FormInputDisabled {
    font-size: 0.7rem !important;
  }
  .FormTitle {
    font-size: 0.8rem;
  }

  .ModalRoot {
    padding: 5px;
    width: 100%;
    min-width: none;
    min-height: 50px;
  }
}
