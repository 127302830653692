.LogoutRoot {
  margin-top: 1rem;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  width: 100%;
}

@media (max-width: 800px) {
}

@media (max-width: 400px) {
}
