.HomeUserRoot {
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  background-color: var(--bg-fundo-secondary);
}

.HomeUserBannerRoot {
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  width: 100%;
  min-height: 200px;
  background-color: var--bg-fundo-children;
}

.HomeUserBannerImgLogo {
  width: 100%;
  max-height: 250px;
  padding: 5px;
}

.HomeUserDocumentsTitle {
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--title);
}
