.PanelCustomPageBox {
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.PanelCustomRoot {
  padding: 1rem;
  width: 100%;
  max-width: 400px;
  min-height: 150px;
  max-height: 300px;
  border-radius: 16px;
  border: 1px solid var(--border-panel);
  -webkit-box-shadow: 10px 10px 24px 5px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 10px 10px 24px 5px rgba(0, 0, 0, 0.51);
  box-shadow: 10px 10px 24px 5px rgba(0, 0, 0, 0.51);
}

.PanelCustomBGColorSuccess {
  background: var(--bg-fundo-panel);
}

.PanelCustomBGColorWarning {
  background: var(--bg-fundo-warning-panel);
}

.PanelCustomBGColorDanger {
  background: var(--bg-fundo-danger-panel);
}

.PanelCustomTitleColorSuccess {
  color: var(--title-panel);
  text-shadow: var(--gray) 1px 3px 2px;
}

.PanelCustomTitleColorWarning {
  color: var(--title-warning-panel);
}

.PanelCustomTitleColorDanger {
  color: var(--title-danger-panel);
  text-shadow: var(--gray) 1px 3px 2px;
}

.PanelCustomTextBigColorSuccess {
  color: var(--text-panel);
}

.PanelCustomTextBigColorWarning {
  color: var(--text-warning-panel);
}

.PanelCustomTextBigColorDanger {
  color: var(--text-danger-panel);
}

.PanelCustomMessageColorSuccess {
  color: var(--text-messsage-panel);
  text-shadow: var(--gray) 1px 3px 2px;
}

.PanelCustomMessageColorWarning {
  color: var(--text-messsage-warning-panel);
}

.PanelCustomMessageColorDanger {
  color: var(--text-messsage-danger-panel);
  text-shadow: var(--gray) 1px 3px 2px;
}

.PanelCustomImg {
  margin: 3px;
  width: 50px;
  color: var(--title-dark);
}

.PanelCustomAlertImg {
  margin: 3px;
  width: 100%;
  max-width: 150px;
  color: var(--title-dark);
}

.PanelCustomTitle {
  font-size: 1rem;
  font-weight: bold;
  /* text-decoration: underline; */
}

.PanelCustomTextBig {
  font-size: 2.5rem;
  font-weight: bold;
}

.PanelCustomTextDark {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--text-panel-dark);
}

.PanelCustomMessage {
  font-size: 0.9rem;
  font-weight: bold;
  font-style: italic;
}

@media (max-width: 800px) {
  .PanelCustomTitle {
    font-size: 0.9rem;
  }
  .PanelCustomTextDark {
    font-size: 2rem;
  }
  .PanelCustomTextBig {
    font-size: 2rem;
  }
}

@media (max-width: 400px) {
  .PanelCustomImg {
    width: 40px;
  }
  .PanelCustomTitle {
    font-size: 0.8rem;
  }
  .PanelCustomTextDark {
    font-size: 1rem;
  }
  .PanelCustomTextBig {
    font-size: 1rem;
  }

  .PanelCustomAlertImg {
    width: 50px;
  }
  .PanelCustomMessage {
    font-size: 0.7rem;
  }
}
