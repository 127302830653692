.AlertBox {
  width: auto;
  height: fit-content;
  margin: 0px;
  padding: 0px;
  position: fixed;
  top: 3rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  background-color: transparent;
  border-radius: 16px;
  -webkit-box-shadow: 10px 10px 24px 5px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 10px 10px 24px 5px rgba(0, 0, 0, 0.51);
  box-shadow: 10px 10px 24px 5px rgba(0, 0, 0, 0.51);
  z-index: 9999998;
}
