html {
  box-sizing: border-box;
}

*,
*:before,
*.after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-size: 1rem !important;
  color: var(--primary) !important;
  font-family: Montserrat-Regular, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica Neue, Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.AppMain {
  margin: 0px;
  padding: 0px;
}

.LayoutRoot {
  width: 100%;
  padding: 3rem 0rem 0rem 0rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  min-height: 90vh;
  background-color: var(--bg-fundo-secondary);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 800px) {
  .LayoutRoot {
    padding: 3.5rem 0rem 0rem 0rem;
  }
}

@media (max-width: 400px) {
  .LayoutRoot {
    padding: 3.5rem 0rem 0rem 0rem;
  }
}

@media (prefers-reduced-motion: no-preference) {
}
