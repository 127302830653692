.homeExportCases {
  width: 100%;
  display: flex;
  margin-top: 2px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
}

.download-table-xls-button {
  border: none;
  background-color: transparent;
  padding: auto;
  text-decoration: underline;
  font-weight: bold;
  font-style: italic;
  font-family: "Oxygen", "Segoe UI", "Roboto", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif";
  font-size: 0.7rem;
  color: #ea6f4c;
}

.homeExportCasesLink {
  padding: auto;
  text-decoration: underline;
  font-weight: bold;
  font-style: italic;
  font-family: "Oxygen", "Segoe UI", "Roboto", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif";
  font-size: 0.7rem;
  color: #ea6f4c;
}
