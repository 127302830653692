:root {
  --link: #3a6d51;
  --linkhover: #343a40;
  --linkSelected: #28a745;
  --shadow: #e0e5cf;
  --bg-fundo-primary: #ffffff;
  --bg-fundo-secondary: #e0e5cf;
  /* --bg-fundo-form-login: #8e2043; */
  --bg-fundo-form-login: #000000;
  --bg-fundo-tertiary: #f4f4f4;
  --bg-fundo-children: #eeeeee;
  --bg-fundo-children-dark: #dfe4d0;
  --bg-fundo-menu: #eeee;
  --bg-fundo-panel: #1580ab;
  --bg-fundo-warning-panel: #fff783;
  --bg-fundo-danger-panel: #f8535c;
  --border-panel: #6c757d;
  --title-panel: #dbdbdb;
  --title-warning-panel: #1580ab;
  --title-danger-panel: #eeee;
  --text-panel: #ffffff;
  --text-warning-panel: #000000;
  --text-danger-panel: #000000;
  --text-panel-dark: #940b12;
  --text-messsage-panel: #eeee;
  --text-messsage-warning-panel: #808080;
  --text-messsage-danger-panel: #eeee;
  --menu-selected: #1580ab;
  --bg-fundo-dark: #1a321a;
  --bg-top-default: #1ba9df;
  --bg-button-primary: #1a984c;
  --bg-button-primary-over: #20ce7d;
  --bg-button-default: #6c757d;
  --bg-button-default-over: #dbdbdb;
  --bg-button-danger: #dc222b;
  --bg-button-danger-over: #940b12;
  --bg-button-tertiary: #4c5153;
  --bg-button-tertiary-over: #1ba9df;
  --bg-button-finish: #f88962;
  --bg-button-finish-over: #fd7e14;
  --text-destak: #fd7e14;
  --text-message: #dc3545;
  --modal-title: #1a984c;
  --title: #00441f;
  --title-dark: #1a321a;
  --titleChildren: #343a40;
  --title-primary: #054320;
  --label: #3a6d51;
  --label-form-login: #ffffff;
  --titleModal: #1580ab;
  --inputfield: #343a40;
  --button-ok-bg-form-login: #00000;
  /* --button-ok-bg: #003560; */
  --button-ok-bg: #dc3545;
  --button-ok-text: #ffffff;
  --button-cancel-bg: #003560;
  --button-cancel-text: #ffffff;
  --breadcrumbs-text: #284f5e;
  --blue: #007bff;
  --blue-dark: #0b475e;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --red-dark: #8e2043;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #b54722;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --font-default: Montserrat, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
