.ReportDetailHeaderRoot {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.ReportDetailHeaderBox {
  width: 100%;
  padding: 5px;
  background-color: var(--bg-fundo-secondary);
}

.ReportDetailHeaderImgLogoBox {
  display: inline-block;
  width: 50px;
  align-items: center;
}

.ReportDetailHeaderImgLogo {
  width: 100%;
  max-width: 3rem;
  padding: 10px;
  border-radius: 16px;
  background: var(--orange);
}

.ReportDetailHeaderTitleBox {
  width: 70%;
  display: inline-block;
  margin: 0px 0px 0px 6px;
  vertical-align: center;
  align-items: center;
}

.ReportDetailHeaderButtonBox {
  width: 25%;
  display: inline-block;
  margin: 0px 0px 0px 6px;
  vertical-align: center;
  align-items: center;
}

.ReportDetailHeaderTitle {
  font-size: 1rem;
  font-weight: bold;
  color: var(--title);
  font-family: var(--font-default);
  box-sizing: border-box;
}

.ReportDetailHeaderSubTitle {
  font-size: 0.8rem;
  font-weight: bold;
  text-decoration: underline;
  color: var(--titleChildren);
  font-family: var(--font-default);
  box-sizing: border-box;
}

.ReportDetailHeaderInfoBox {
  padding: 3px;
  background: var(--bg-fundo-tertiary);
}

.ReportDetailHeaderFieldsBox {
  width: 100%;
  padding: 1px;
}

.ReportDetailHeaderFieldsLabel {
  font-size: 0.8rem;
  font-weight: bold;
  font-family: var(--font-default);
  color: var(--label);
}

.ReportDetailHeaderFieldsText {
  font-size: 1rem;
  font-family: var(--font-default);
  color: var(--inputfield);
}

.ReportDetailFeedBox {
  width: 100%;
  padding: 5px;
}

.ReportDetailFeedImgLogo {
  width: 100%;
  max-width: 2rem;
  padding: 5px;
  border-radius: 16px;
  background: var(--blue);
}

.ReportDetailFeedItemImg {
  width: 1rem;
  height: 1rem;
  padding: 5px;
  border-radius: 50%;
  background: var(--gray);
  color: var(--white);
}

@media (max-width: 800px) {
  .ReportDetailHeaderTitle {
    font-size: 0.8rem;
  }

  .ReportDetailHeaderSubTitle {
    font-size: 0.7rem;
  }
  .ReportDetailHeaderFieldsLabel {
    font-size: 0.7rem;
  }
}

@media (max-width: 400px) {
  .ReportDetailHeaderTitle {
    font-size: 0.8rem;
  }
  .ReportDetailHeaderSubTitle {
    font-size: 0.7rem;
  }
  .ReportDetailHeaderFieldsLabel {
    font-size: 0.7rem;
  }
}
