table.calendarTable {
  margin: 0 auto;
}

table.calendarTable td {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
}

table.calendarTable th {
  font-size: 0.8rem;
  padding: 3px;
  text-align: center;
}

.today {
  background-color: #003865;
  color: #fff;
  border-radius: 50%;
  margin: 0.1rem;
}

.in-prev-month,
.in-next-month {
  opacity: 0.5;
  padding: 5px;
}
.in-month {
  padding: 5px;
}

.calendarButton {
  background: var(--bg-fundo-children);
  font-size: 1rem;
  color: var(--blue);
}

.dayDefault {
  font-size: 0.8rem;
}

.daySelected {
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--text-destak);
}

.calendarBox {
  padding: 0.8rem 0.3rem 0.3rem 0.3rem;
  width: 100%;
  min-height: 100px;
  max-height: 330px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  overflow: auto;
}

.calendarBoxPage {
  padding: 0.8rem 0.3rem 0.3rem 0.3rem;
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  overflow: auto;
}

.calendarBoxTasks {
  padding: 0.3rem 0.3rem 0.3rem 0.3rem;
  width: 100%;
  min-height: 300px;
  max-height: 350px;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  overflow: auto;

  /* border-bottom: 1px solid #808080; */
  border-left: 1px solid #808080;
  /* border-bottom-right-radius: 8px; */
  /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */
  margin: 0.1rem 0.1rem 0.3rem 0.1rem;
}

.cardEventDateHeaderWeek {
  margin-bottom: 0.3rem;
}
.calendarEventTitle {
  font-weight: bold;
  font-size: 0.8rem;
  color: var(--cor-background-blue);
}

.calendarEventColWeek {
  width: 14.28%;
}

.calendarItemBoxWeek {
  width: 100%;
  padding: 0rem;
  text-align: justify;
  word-wrap: break-word;
}

.calendarCountBoxMonth {
  width: 100%;
  padding: 0rem;
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.calendarCountTextMonth {
  font-size: 0.8rem;
  font-weight: bold;
  color: #808080;
}

.calendarItemIconWeek {
  font-size: 0.8rem;
  margin-right: 0.2rem;
  color: var(--blue);
}
.calendarItemDestakWeek {
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--gray);
}

.calendarItemTextWeek {
  font-size: 0.8rem;
  color: var(--gray);
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
}

.cardEventDateTitleWeek {
  font-weight: bold;
  font-size: 0.8rem;
  color: var(--cor-background-orange);
}

.cardGridDateWeek {
  width: 100%;
  padding: 0rem 0.2rem 0rem 0.2rem;
  border-left: 1px solid #6c757d;
}

.cardGridDateMonth {
  width: 100%;
  height: 90px;
  padding: 0rem 0.2rem 0rem 0.2rem;
  border-left: 1px solid #6c757d;
  border-bottom: 1px solid #6c757d;
}

.cardGridLastDateMonth {
  border-right: 1px solid #6c757d;
}

.CalendarCustomHomeBox {
  width: 100%;
  min-height: 300px;
  max-height: 350px;
  padding: 0.5rem;
  /* border-bottom: 1px solid #f4f4f4; */
  /* border-right: 1px solid #f4f4f4; */
  /* border-bottom-right-radius: 8px; */
  /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */
  margin: 0.1rem 0.1rem 0.3rem 0.1rem;
}

.CalendarCustomHomeTitleBox {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.cardGridHourDay {
  border-bottom: 1px solid #f4f4f4;
  border-right: 1px solid #f4f4f4;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  border-bottom-right-radius: 8px;
}

@media (max-width: 600px) {
  .calendarEventColWeek {
    width: 50%;
  }
}

@media (max-width: 400px) {
  .calendarEventColWeek {
    width: 100%;
  }
}
