#HeaderPerfil span {
  font-size: 0.8rem;
  text-decoration: none;
  font-weight: bold;
  color: var(--gray-dark);
}

#HeaderPerfilBox {
  width: 100%;
}

#HeaderProfileMenu {
  margin-top: 35px;
  z-index: 99999;
}

@media (max-width: 800px) {
  #HeaderPerfil span {
    font-size: 0.7rem;
  }
}

@media (max-width: 400px) {
  #HeaderPerfil span {
    font-size: 0.6rem;
  }
}
