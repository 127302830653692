.FooterRoot {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  background-color: var(--bg-fundo-primary);
  min-height: 3rem;
  width: 100%;
}

.FooterRoot a {
  margin: 0.3rem;
  text-decoration: none;
  color: var(--link);
}
.FooterRoot a:hover {
  margin: 0.3rem;
  text-decoration: underline;
  font-weight: bold;
  color: var(--linkhover);
}

.FooterText {
  font-size: 1rem;
}

@media (max-width: 800px) {
  .FooterText {
    font-size: 0.8rem;
  }
}

@media (max-width: 400px) {
  .FooterText {
    font-size: 0.7rem;
  }
}
