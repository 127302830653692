.inpuRoot {
  width: 99%;
  display: flex;
  justify-content: center;
  align-content: flex-start;
}

.inputCustomDefault {
  border: 1px solid rgb(0, 56, 101);
  border-radius: 15px;
  background: #ffffff;
  width: 100%;
  padding: 3px 8px 3px 8px;
  margin: 3px 3px 3px 3px;
  font-size: 0.8rem;
  color: #4d4d4d;
  min-height: 30px;
  max-height: 38px;
}

.inputCustomDefaultDisabled {
  border: 1px solid rgb(0, 56, 101);
  border-radius: 15px;
  background: #e9e9e9;
  width: 100%;
  padding: 3px 8px 3px 8px;
  margin: 3px 3px 3px 3px;
  font-size: 0.8rem;
  color: #4d4d4d;
  min-height: 30px;
  max-height: 38px;
}

.inputLabelDefault {
  margin-top: 5;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--label);
}

.inputIconField {
  color: #4d4d4d;
  font-size: 0.8rem;
  margin-right: 5;
}

.inputLabelBoxError {
  margin-left: 5;
  margin-bottom: 5;
}

.inputLabelFieldError {
  font-size: 0.8rem;
  font-weight: bold;
  color: #a50050;
}

.inputIconFieldError {
  font-size: 0.8rem;
  color: #a50050;
  margin-right: 5;
}

.inputLabelBox {
  margin-left: 5;
}

.FieldsByGroupBox {
  width: 100%;
  padding: 1px;
}

.inputTextField {
  font-size: 0.8rem;
  border-radius: 15px;
  max-height: 38px;
  width: 98%;
  margin: 3px 5px 3px 5px;
  color: #4d4d4d;
}

@media (max-width: 800px) {
  .inputCustomDefault {
    font-size: 0.7rem;
  }

  .inputCustomDefaultDisabled {
    font-size: 0.7rem;
  }

  .inputTextField {
    font-size: 0.7rem;
  }

  .inputLabelDefault {
    font-size: 0.7rem;
  }

  .inputIconField {
    font-size: 0.7rem;
  }

  .inputLabelFieldError {
    font-size: 0.7rem;
  }
  .inputIconFieldError {
    font-size: 0.7rem;
  }
}

@media (max-width: 400px) {
  .inputCustomDefault {
    font-size: 0.7rem;
  }

  .inputCustomDefaultDisabled {
    font-size: 0.7rem;
  }

  .inputLabelDefault {
    font-size: 0.7rem;
  }

  .inputIconField {
    font-size: 0.7rem;
  }

  .inputLabelFieldError {
    font-size: 0.7rem;
  }

  .inputIconFieldError {
    font-size: 0.7rem;
  }

  .inputTextField {
    font-size: 0.7rem;
  }
}
