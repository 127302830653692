@import url("logo.css");
@import url("perfil.css");

.HeaderRoot {
  padding: 0px 5px 0px 5px;
  position: fixed;
  top: 0rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  min-height: 3rem;
  background-color: var(--red-dark);
  width: 100%;
  z-index: 999996;
}

.HeaderRoot a {
  font-size: 0.8rem;
  margin: 0.3rem;
  text-decoration: none;
  color: var(--link);
}
.HeaderRoot a:hover {
  font-size: 0.8rem;
  margin: 0.3rem;
  text-decoration: underline;
  font-weight: bold;
  color: var(--linkhover);
}

.HeaderVersion {
  margin: 0px;
  padding: 0px;
  width: 100%;
  font-size: 0.7rem;
  color: var(--gray);
  border-top: 1px solid var(--gray);
}

.HeaderMenu {
  min-height: 1.5rem;
}

.HeaderMenuText {
  margin: 0px;
  padding: 0px;
  width: 100%;
  font-size: 0.9rem;
  color: var(--label);
}

.HeaderMenuNotSelected {
  background-color: transparent;
  min-height: 1rem;
  width: auto;
}
.HeaderMenuSelected {
  color: var(--linkSelected) !important;
  min-height: 1rem;
}

.HeaderTextHello {
  color: var(--text-panel) !important;
}

.HeaderMenuLinkBox {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.HeaderMenuIcon {
  color: var(--white);
}
@media (max-width: 800px) {
  .HeaderRoot a {
    font-size: 0.7rem;
  }
  .HeaderRoot a:hover {
    font-size: 0.7rem;
  }
}

@media (max-width: 400px) {
  .HeaderRoot a {
    font-size: 0.7rem;
  }
  .HeaderRoot a:hover {
    font-size: 0.7rem;
  }
}
