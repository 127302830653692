.LoginRoot {
  /* background: linear-gradient(
    270deg,
    #0017de,
    #324fdb,
    #446df2,
    #3265db,
    #3980f9,
    #001556
  ); */

  /* 2023
  background: linear-gradient(
    270deg,
    #007000,
    #19ff19,
    #00bd00,
    #004c4a,
    #00ba00,
    #002923
  ); 
  */

  /* 
  background: linear-gradient(
    270deg,
    #db3e78,
    #b522ab,
    #b52e22,
    #b52254,
    #8a22b5,
    #b54722,
    #5b1b23
  ); */
  /* 
  background: linear-gradient(
    270deg,
    #db3e78,
    #b52254,
    #b52e22,
    #b54722,
    #5b1b23,
    #8e2043
  ); */

  background: linear-gradient(#8e2043, #5b1b23);

  background-size: 800% 800%;

  -webkit-animation: BGLogin 30s ease infinite;
  -moz-animation: BGLogin 30s ease infinite;
  -o-animation: BGLogin 30s ease infinite;
  animation: BGLogin 30s ease infinite;

  padding: 5rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  width: 100%;
  min-height: 100vh;
}

.LoginForm {
  padding: 1.5rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: center;
  width: 50%;
  max-width: 400px;
  min-height: 300px;
  border-radius: 16px;
  /* background-color: var(--bg-fundo-primary); */
  background-color: var(--bg-fundo-form-login);
  -webkit-box-shadow: 10px 10px 24px 5px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 10px 10px 24px 5px rgba(0, 0, 0, 0.51);
  box-shadow: 10px 10px 24px 5px rgba(0, 0, 0, 0.51);
}

.LoginFormRow {
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
}

.LoginFormRow label {
  font-size: 0.9rem !important;
  font-weight: bold !important;
  color: var(--label-form-login) !important;
}

.LoginFormInput {
  width: 80% !important;
  font-size: 1rem !important;
  color: var(--inputfield) !important;
}

.LoginFormInputPassword {
  width: 70% !important;
  font-size: 1rem !important;
  color: var(--inputfield) !important;
}

.LoginTitle {
  color: var(--title);
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: var(--shadow) 1px 3px 1px;
}

.LoginLogo {
  width: 200px;
}

.LoginButtonOK {
  height: 48px;
  margin: 3px;
  width: 60%;
  /* background-color: var(--button-ok-bg-form-login) !important; */
  background: linear-gradient(#8e2043, #5b1b23);
  color: var(--button-ok-text) !important;
  font-size: 1.2rem !important;
  font-weight: bold !important;
  border-radius: 16px !important;
  border: 0px !important;
  border-bottom: 1px solid #ffffff !important;
  border-right: 1px solid #ffffff !important;
}
.LoginTextVersion {
  color: #808080;
}

@-webkit-keyframes BGLogin {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes BGLogin {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-o-keyframes BGLogin {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes BGLogin {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 800px) {
  .LoginForm {
    width: 80%;
  }
  .LoginFormRow {
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  }
  .LoginFormInput .LoginFormInputPassword {
    font-size: 0.8rem !important;
  }

  .LoginFormRow label {
    font-size: 0.8rem !important;
  }
  .LoginLogo {
    width: 150;
  }
}

@media (max-width: 400px) {
  .LoginForm {
    width: 95%;
  }
  .LoginFormRow {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }
  .LoginFormInput .LoginFormInputPassword {
    font-size: 0.7rem !important;
  }
  .LoginFormRow label {
    font-size: 0.7rem !important;
  }
  .LoginLogo {
    width: 100px;
  }
  .LoginButtonOK {
    font-size: 0.8rem !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
}
